import React, { createContext, useState, useEffect, ReactNode, useCallback } from 'react';
import { getSchedulePageData } from '../../../api/schedule/getSchedule';
import { ScheduleItem, SchedulePageInfo, timeItem } from '../interfaces/interfaces'; 
import { scheduleType } from '../types/scheduleType'; 

interface IScheduleContextType {
  currentScheduleType: scheduleType;
  infoObject: SchedulePageInfo;
  toggleScheduleType: () => void;
  scheduleData: SchedulePageInfo['scheduleData'];
  downTimes: SchedulePageInfo['downTimes'];
  availableTimes: SchedulePageInfo['availableTimes'];
}

export const ScheduleContext = createContext<IScheduleContextType | undefined>(undefined);

export const ScheduleProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [currentScheduleType, setScheduleType] = useState<scheduleType>('private');
  const [infoObject, setInfoObject] = useState<SchedulePageInfo>({
    headerText: '',
    hints: {
      privateLessonHint: '',
      groupLessonHint: ''
    },
    scheduleData: [],
    downTimes: [],
    availableTimes: []
  });

  const initScheduler = useCallback(()=>{
    const fetchSchedulePageData = async () => {
      const data = await getSchedulePageData();
      setInfoObject(data);
      calculateAvailableTimes(data.scheduleData, data.downTimes);
    };
    fetchSchedulePageData();
  },[]);

  useEffect(() => {
    initScheduler();
  }, [initScheduler]);

  const toggleScheduleType = () => {
    setScheduleType(prev => (prev === 'private' ? 'group' : 'private'));
  };

  const calculateAvailableTimes = (scheduleData: ScheduleItem[], downTimes: timeItem[]) => {
    //Helper
    const generateAllHours = (): string[] => {
      const hours: string[] = [];
      for (let h = 8; h <= 20; h++) {
        const hour = h < 10 ? `0${h}:00` : `${h}:00`;
        hours.push(hour);
      }
      return hours;
    };

    const allHours = generateAllHours();
    
    const availableTimes: timeItem[] = scheduleData.map(day => {
      // Collect booked hours
      const bookedHours = day.lessons.map(lesson => lesson.hour);

      // Collect downtime hours for the selected day
      const downtimesForSelectedDay = downTimes.find(downtime => 
        new Date(downtime.date).toISOString().split('T')[0] === day.date.toISOString().split('T')[0]
      );
      const downtimeHours = downtimesForSelectedDay ? downtimesForSelectedDay.hours : [];

      // Calculate available hours
      const availableHours = allHours.filter(hour => 
        !bookedHours.includes(hour) && !downtimeHours.includes(hour)
      );

      return {
        _id: day._id, 
        date: day.date,
        hours: availableHours
      };
    });

    setInfoObject(prev => ({
      ...prev,
      availableTimes
    }));
  }

  return (
    <ScheduleContext.Provider value={{ 
      currentScheduleType, 
      infoObject, 
      toggleScheduleType,
      scheduleData: infoObject.scheduleData,
      downTimes: infoObject.downTimes,
      availableTimes: infoObject.availableTimes
    }}>
      {children}
    </ScheduleContext.Provider>
  );
}

